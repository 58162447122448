"use client";
import Image from "next/image";
import { firebase } from "@aether/shared/lib/firebase/firebase.client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthContextProvider } from "@aether/shared/context/auth";
import { Header2 } from "@/components/organisms/header";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Link from "next/link";
import Footer from "@/components/organisms/footer";
import { LogOut } from "lucide-react";
import { useRouter } from "next/navigation";
import { signOut } from "firebase/auth";

interface ClientWrapperInterface {
  children?: React.ReactNode;
}

const queryClient = new QueryClient();

export const ClientWrapper: React.FC<ClientWrapperInterface> = ({
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider firebase={firebase}>
        <Header2 />
        <div className="mb-10 min-h-screen">
          {children}
        </div>
        <Footer />
      </AuthContextProvider>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </QueryClientProvider>
  );
};


export const SubClientWrapper: React.FC<ClientWrapperInterface> = ({
  children,
}) => {
  const router = useRouter()

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider firebase={firebase}>
        <div className="min-h-screen p-[2%]">
          <nav className="flex justify-center p-5">
            <Link href="/">
              <Image
                src="/logo.png"
                alt="Aether Logo"
                width={400}
                height={400}
                className="h-6 w-auto"
              />
            </Link>
          </nav>
          <button onClick={async () => {
            try {
              await signOut(firebase.auth)
              router.push("/")
            } catch (error) {
              console.error("Logout failed", error);
            }
          }} className="absolute top-5 right-5">
            <LogOut />
          </button>
          {children}
        </div>
      </AuthContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

