"use client";
import { Calendar } from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { zodResolver } from "@hookform/resolvers/zod";
import { Calendar as LucidCalendar, Check, MapPin } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import locations from "@aether/shared/utils/location.json";
import { format } from "date-fns";
import { useRouter, useSearchParams } from "next/navigation";
import { useDebouncedCallback } from "@/hooks/debounce-callback";
import { startTransition, useState } from "react";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "./ui/dialog";
import { LoginForm } from "./organisms/login-form";

const SearchSchema = z.object({
  privateEventCode: z.string(),
  dateRange: z.object({
    from: z.date().optional(),
    to: z.date().optional(),
  }),
  locationId: z.string(),
});

type SearchType = z.infer<typeof SearchSchema>;

export default function HomePageSearch() {
  const [locationInput, setLocationInput] = useState("");
  const _searchParams = useSearchParams();
  const [open, setOpen] = useState(false)

  const dateFromParam = _searchParams.get("from");
  const dateToParam = _searchParams.get("to");
  const locationParam = _searchParams.get("location");

  const form = useForm<SearchType>({
    resolver: zodResolver(SearchSchema),
    defaultValues: {
      privateEventCode: "",
      dateRange: {
        from: dateFromParam ? new Date(dateFromParam) : undefined,
        to: dateToParam ? new Date(dateToParam) : undefined,
      },
      locationId: locationParam,
    },
  });

  const locationSearch = useDebouncedCallback((locationId: string) => {
    const search = locationId;
    const searchParams = new URLSearchParams(_searchParams.toString());

    if (!search) searchParams.delete("location");
    else searchParams.set("location", search);

  }, 300);

  const dateSearch = useDebouncedCallback(
    ({ from, to }: { from?: Date; to?: Date }) => {
      const searchDateFrom = from;
      const searchDateTo = to;
      const searchParams = new URLSearchParams(_searchParams.toString());

      if (!searchDateFrom) searchParams.delete("from");
      else searchParams.set("from", format(searchDateFrom, "yyyy-MM-dd"));

      if (!searchDateTo) searchParams.delete("to");
      else searchParams.set("to", format(searchDateTo, "yyyy-MM-dd"));
    },
    300,
  );

  const date = form.watch("dateRange");
  const locationId = form.watch("locationId");
  const filteredLocations = locations.RECORDS.filter(
    (record) =>
      record.name.toLowerCase().includes(locationInput.toLowerCase()) &&
      locationInput.length > 1,
  );

  return (
    <div className="grid justify-center">
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="max-w-xl">
          <DialogHeader>
            <DialogTitle className="text-xl">Log in to AetherLenz</DialogTitle>
            <DialogDescription>
              Find your photos here at AetherLenz
            </DialogDescription>
          </DialogHeader>
          <LoginForm />
        </DialogContent>
      </Dialog>
      <Form {...form}>
        <form className="flex gap-3 justify-center items-center bg-neutral-100 rounded-full h-16 min-w-32 px-6 w-fit mx-auto">
          <FormField
            control={form.control}
            name="dateRange"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <Popover>
                  <PopoverTrigger asChild>
                    <button className="aspect-square size-10 bg-white text-aether-blue rounded-full flex items-center justify-center" onClick={() => setOpen(true)
                    }>
                      <LucidCalendar />
                    </button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="center">
                    <Calendar
                      // disabled={new Date()}
                      modifiers={{ test: [new Date()] }}
                      initialFocus
                      mode="range"
                      defaultMonth={field.value?.from}
                      // @ts-ignore
                      selected={field.value}
                      onSelect={(dateRange) => {
                        if (dateRange) {
                          form.setValue("dateRange", dateRange);
                          dateSearch(dateRange);
                        } else {
                          form.setValue("dateRange", {
                            from: undefined,
                            to: undefined,
                          });
                          dateSearch({
                            from: undefined,
                            to: undefined,
                          });
                        }
                      }}
                      numberOfMonths={1}
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="locationId"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Popover>
                    <PopoverTrigger asChild>
                      <button className="aspect-square size-10 bg-yellow-400 rounded-full flex items-center justify-center" onClick={() => setOpen(true)}>
                        <MapPin />
                      </button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[250px] p-0 ">
                      <span className="relative">
                        <Input
                          onChange={(e) => setLocationInput(e.target.value)}
                          placeholder="Enter location..."
                          className="border-0 focus:border-0 focus:ring-0 focus:ring-offset-0 rounded-none"
                        />
                      </span>
                      <div className="max-h-[200px] overflow-y-scroll">
                        {filteredLocations.map((location) => (
                          <button
                            key={location.id}
                            onClick={() => {

                              const newLocation = field.value === location.id.toString() ? undefined : location.id.toString();
                              form.setValue(
                                "locationId",
                                newLocation,
                              );
                              locationSearch(newLocation);
                            }}
                            className="w-full text-xs px-2 hover:bg-zinc-100 flex justify-left text-left py-1.5"
                          >
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                field.value === location.id.toString()
                                  ? "opacity-100"
                                  : "opacity-0",
                              )}
                            />
                            {location.name}
                          </button>
                        ))}
                      </div>
                    </PopoverContent>
                  </Popover>
                </FormControl>
              </FormItem>
            )}
          />

          <div className="hidden lg:flex gap-3">
            {date.from ? (
              date.to ? (
                <div>
                  <p className="text-white text-xs">Date</p>
                  <p className="text-white">
                    {format(date.from, "MMM d")} - {format(date.to, "MMM d")}
                  </p>
                </div>
              ) : (
                <div>
                  <p className="text-white text-xs">Date</p>
                  <p className="text-white">{format(date.from, "MMM d")}</p>
                </div>
              )
            ) : null}
            {locationId ? (
              <div>
                <p className="text-yellow-500 text-xs">Location</p>
                <p className="text-yellow-500">
                  {locations.RECORDS.find(
                    (location) => location.id.toString() === locationId,
                  )
                    ?.name.split(",")
                    .pop()}
                </p>
              </div>
            ) : null}
          </div>
        </form>
      </Form>
      <div className="flex gap-2 items-center lg:hidden pt-3">
        {date.from ? (
          date.to ? (
            <div className="flex text-xs items-center bg-zinc-100 rounded-full gap-2 px-3 py-1.5">
              <LucidCalendar className="h-4 text-aether-blue" />
              <p className="">
                {format(date.from, "MMM d")} - {format(date.to, "MMM d")}
              </p>
            </div>
          ) : (
            <div>
              <p className="text-white text-xs">Date</p>
              <p className="text-white">{format(date.from, "MMM d")}</p>
            </div>
          )
        ) : null}
        {locationId ? (
          <div className="flex text-xs items-center bg-zinc-100 rounded-full gap-2 px-3 py-1.5">
            <MapPin className="h-4" />
            <p className="text-xs">
              {locations.RECORDS.find(
                (location) => location.id.toString() === locationId,
              )
                ?.name.split(",")
                .pop()}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
