"use client"
import { Drawer, DrawerContent, DrawerTrigger } from '../ui/drawer';
import { CircleX, Gem, ShoppingCart, Trash, X, ZoomIn } from "lucide-react";
import { Button } from "@/components/ui/button";
import useCart from "@/hooks/use-cart";
import useWallet from "@/hooks/use-wallet";
import { usePayment } from "@/hooks/use-payment";
import { useState } from "react";
import { toast } from "sonner";
import { useRouter } from "next/navigation";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Separator } from '../ui/separator';
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../ui/dialog';


export function CartDrawer() {
  const {
    fetchCart: { data, isLoading },
    deleteCart,
  } = useCart({});
  const {
    fetchWallet: { data: walletData, isLoading: isWalletLoading },
  } = useWallet();

  const { executePhotoClaim } = usePayment();
  const [claimLoading, setClaimLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const router = useRouter();

  return <Drawer open={open} onOpenChange={setOpen}>
    <DrawerTrigger>
      <div className="relative p-1.5 aspect-square border rounded-lg w-fit bg-white">
        {data?.cart.cartItems.length ? (
          <span className="bg-sky-300 text-white font-semibold rounded-full w-4 h-4 flex items-center justify-center absolute -right-2 -top-1 text-xs">
            {data?.cart.cartItems.length}
          </span>
        ) : null}
        <ShoppingCart className="h-6 w-6" />
      </div>
    </DrawerTrigger>
    <DrawerContent className="min-h-[300px] backdrop-blur rounded-lg overflow-x-hidden px-2 lg:px-40 overflow-y-hidden">
      <ScrollArea>
        <div className="flex rounded-lg gap-2 pt-5 lg:pt-10 pb-3 justify-center">
          {!isLoading && data ? data.cart.cartItems.map(cartItem => <span key={cartItem.id} className="relative aspect-square h-[250px] w-[250px] rounded-lg overflow-hidden">
            <img
              alt="cart item photo"
              className="aspect-square object-cover rounded-lg"
              height={500}
              width={500}
              src={cartItem.photo.key}
            />

            <Dialog>
              <DialogClose className="text-white" />
              <DialogTrigger asChild>
                <button className="absolute bottom-2.5 left-2.5 p-1 aspect-square rounded-full bg-white/60 flex items-center justify-center">
                  <ZoomIn className="h-5 w-5" />
                </button>
              </DialogTrigger>
              <DialogContent className="p-0 bg-transparent  m-0 w-full lg:w-fit">
                <img
                  alt={cartItem.photo.name}
                  className="rounded-lg"
                  src={cartItem.photo.previewKey}
                />
              </DialogContent>
            </Dialog>

            <span className="absolute flex items-center top-0 left-0 bg-white rounded-br-lg py-1 px-2 border-t border-l rounded-tl-lg">
              <Avatar className="w-4 h-4">
                <AvatarImage
                  src={cartItem.photo.collection.organizer.photoURL}
                  alt="@shadcn"
                />
                <AvatarFallback>
                  {cartItem.photo.collection.organizer.displayName.substring(0, 2)}
                </AvatarFallback>
              </Avatar>
              <p className="text-xs pl-1">
                {cartItem.photo.collection.organizer.displayName ?? ""}
              </p>
            </span>


            <button
              className="flex items-center absolute bottom-2.5 right-2.5 bg-white rounded-full gap-1 border-zinc-300 px-2 h-[25px]"
              onClick={() => {
                deleteCart.mutate({ photoId: cartItem.photoId });
              }}
            >
              <Trash className="h-3 w-3 text-red-500" />
              <Separator orientation="vertical" />
              <p className="items-center text-xs flex pl-1 gap-0.5 font-semibold">
                {cartItem.photo.collection.token}<Gem className="h-4 w-4 text-green-500" />
              </p>
            </button>
          </span>)
            : null}
        </div>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>

      {
        data ? data.cart.cartItems.length > 0 ? <div className="my-2 grid">
          {walletData && data && (walletData.token <= 0 || walletData.token < data.info.subtotal) ?
            <small className="text-red-500 text-center">
              Not enough token. Please add more to proceed.
            </small> : null
          }
          <Button type="button" className="my-2 w-fit mx-auto bg-aether-blue hover:bg-aether-blue/90"
            onClick={async () => {
              setClaimLoading(true);
              try {
                await executePhotoClaim.mutateAsync();
                toast.success("Photos are now claimed!");
                setClaimLoading(false);
                setOpen(false);
                router.push("/my-photos");
              } catch (e) {
                toast.error("Failed to claim photos");
                setClaimLoading(false);
              }
            }}
            disabled={
              isLoading ||
              isWalletLoading ||
              !data ||
              !walletData ||
              walletData.token <= 0 ||
              walletData.token < data.info.subtotal ||
              claimLoading
            }
          >
            <p className="flex items-center text-xs gap-1.5">
              Claim Photos - <span className="flex items-center gap-0.5">
                {data.info.subtotal}<Gem className="ml-0.5 h-4 w-4 text-green-400" />
              </span>
            </p>
          </Button>
        </div> : <span className="flex justify-center flex-col items-center gap-3 h-full">
          <ShoppingCart className="h-20 w-20 text-aether-blue" />
          <h2 className="text-lg">
            your cart is empty
          </h2>
          <p className="text-xs font-light">
            Looks like you haven&apos;t added anything to your cart yet.
          </p>
        </span> : null}
    </DrawerContent>
  </Drawer>
}
