import { useQuery } from "@tanstack/react-query";

import { getUserTokenFromCookie } from "@aether/shared/services/util-service";
import { getWallet } from "@aether/shared/services/wallet-service";

export const getWalletKey = "get-wallet-key";

export default function useWallet() {
  const fetchWallet = useQuery({
    queryFn: async () => await getWallet(getUserTokenFromCookie()),
    queryKey: [getWalletKey],
  });

  return { fetchWallet };
}
